export const studentData = {
    drafrList : [
        {name:'课程名称',text: 'courseName'},
        {name:'姓名',text: 'name'},
        {name:'学号',text: 'numberId'},
        {name:'手机号',text: 'phone'},
    ],
    currentPage:1,
    pageSize: 10,
    total: 0,
    loading: true,
    operation: ['删除'],//,'成员权限'
    spreadTable:[],
    tableHeight: 400,
    pageType: 1,
    lastState: 1000,

    userInput: undefined,
    jobNumber: undefined,

    showRoleDia: false,
    userItem:{},
    userValue:'',
    userOptions:[],


    loadingStudent: false,
    tableHeightStudent:300,
    operationStudent: ['拉入'],//,'成员权限'
    pageSizeStudent:10,
    currentPageStudent: 1,
    totalStudent: 0,
    drafrListStudent: [
        {name:'姓名',text: 'name'},
        {name:'学号',text: 'numberId'},
        {name:'手机号',text: 'phone'},
    ],
    spreadTableStudent: []
}